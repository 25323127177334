import { FormEvent, useRef } from "react"
import { useAsync, useEvent } from "./hooks"
import { noop } from "./util"

export function useFormHandlers<V, E, A extends any[]>(
    validate: (values: V, isSubmitting: boolean, ...args: A) => Promise<Partial<E>>,
    ...args: A
): [
        (submit: () => void) => (e: FormEvent<HTMLFormElement>) => void,
        () => void,
        (values: V) => Promise<Partial<E>>
    ] {
    const isSubmittingRef = useRef(false)
    const submitRef = useRef<(e: FormEvent<HTMLFormElement>) => void>(noop)

    const onSubmit = useEvent((e: FormEvent<HTMLFormElement>) => {
        isSubmittingRef.current = true
        submitRef.current(e)
    })

    const onSubmitCb = useEvent(submit => {
        submitRef.current = submit
        return onSubmit
    })

    const onReset = useEvent(() => {
        isSubmittingRef.current = false
    })

    const isSubmitting = useEvent(() => isSubmittingRef.current)

    const [handleValidate] = useAsync(async function* (values: V) {
        const errors = await validate(values, isSubmitting(), ...args)
        if (Object.keys(errors).length > 0) {
            isSubmittingRef.current = false
        }

        return errors
    })

    return [onSubmitCb, onReset, handleValidate]
}