import cn from 'classnames'
import CoinIcon from './CoinIcon'
import { Coin } from './types'

interface Props {
    coin?: Coin | null
    shift?: number
    size?: number
    className?: string
    iconClassName?: string
}

export default function MultiCoinIcon({
    coin,
    shift = 17,
    size = 32,
    className,
    iconClassName
}: Props) {
    if (coin == null) {
        return <CoinIcon size={size} code={''} className={cn(iconClassName, className)} />
    }

    if (coin.type !== 'multi') {
        return (
            <CoinIcon size={size} code={coin.code} className={cn(iconClassName, className)} />
        )
    }

    const n = (coin.data.coins.length <= 3 ? coin.data.coins.length : (coin.data.coins.length + 1))

    return (
        <div className={cn('whitespace-nowrap text-left', { }, className)} style={{
            width: (size - shift - 3) * (n - 1) + size
        }}>
            {coin.data.coins.map((code, i) => (
                <CoinIcon size={size} key={code} code={code} className={cn('relative inline-block', iconClassName)} style={{ left: -shift * i }} />
            ))}
        </div>
    )
}