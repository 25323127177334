import cn from 'classnames'
import { createContext, ReactNode, useMemo, useRef, useState, useContext, RefObject } from 'react'
import { delay, requestAnimationFrame } from '@repaya/commons/async2'
import { useAsync, useEffectOnce, useEvent, useKeyMemo } from '../common/hooks'
import { noop } from '../common/util'
import Arrow from './arrow.svg'
import { useEffect } from 'react'

type Size = 'default' | 'wide'

type ContentState = 'visible' | 'hidden' | 'measure'

export interface PanelHandlers {
    tab: string | null
    onSwitchTab(tab: string): () => Promise<void>
    onAdjustContent(): void
}

export interface Tab {
    tab: string
    label?: ReactNode
    size?: 'default' | 'wide'
}

export interface PanelControl {
    tab: string | null
    onSwitchTab(tab: string): () => Promise<void>
    onAdjustContent(): void
    [x: string | number | symbol]: unknown
}

interface PanelControlInternal extends PanelControl {
    tabs: Tab[]
    size: Size
    isAnimationsEnabled: boolean
    height: number | undefined
    contentState: ContentState
    contentRef: RefObject<HTMLDivElement>
    handlers: PanelHandlers
}

interface State {
    currentTab: Tab
    size: Size
    isAnimationsEnabled: boolean
    contentState: ContentState
}

export function usePanel(tabs: Tab[], tab?: string | null): PanelControl {
    const defaultTab = tab != null ? tabs.find(t => t.tab === tab)! : tabs[0]
    const [{currentTab, size, contentState, isAnimationsEnabled}, setState] = useState<State>({
        currentTab: defaultTab,
        size: defaultTab.size ?? 'default',
        isAnimationsEnabled: false,
        contentState: 'visible'
    })
    const [height, setHeight] = useState<number | undefined>(undefined)
    const ref = useRef<HTMLDivElement>(null)

    const updatedTabsRef = useRef(tabs)
    updatedTabsRef.current = tabs

    const [handleSwitchTab] = useAsync(async function* (tab: string) {
        if (currentTab.tab === tab) return

        setState(prev => ({...prev, isAnimationsEnabled: true, contentState: 'hidden'}))
        yield* delay(140)
        yield* requestAnimationFrame()

        const tabObject = updatedTabsRef.current.find(t => t.tab === tab) ?? tabs[0]
        setState(prev => ({ ...prev, currentTab: tabObject, size: tabObject.size ?? 'default', contentState: 'measure'}))
    })

    const handleKeySwitchTab = useKeyMemo(tab => () => handleSwitchTab(tab as string), [handleSwitchTab])

    const handleContentAdjust = useEvent(() => {
        if (ref.current == null) return
        const rect = ref.current.getBoundingClientRect()
        setHeight(rect.height)
    })

    const handlers = useMemo<PanelHandlers>(() => ({
        tab: currentTab.tab,
        onSwitchTab: handleKeySwitchTab,
        onAdjustContent: handleContentAdjust
    }), [currentTab])

    const [handleMeasureAndShow] = useAsync(async function* () {
        yield* delay(170)

        if (ref.current == null) return

        handleContentAdjust()

        yield* delay(30)
        yield* requestAnimationFrame()

        setState(prev => ({ ...prev, contentState: 'visible', isAnimationsEnabled: false}))
    })

    useEffect(() => {
        if (contentState === 'measure') {
            handleMeasureAndShow()
        }
    }, [contentState])

    useEffect(() => {
        if (ref.current == null) return

        const observer = new ResizeObserver(handleContentAdjust)
        observer.observe(ref.current)

        return () => { observer.disconnect() }
    })

    useEffectOnce(() => {
        handleContentAdjust()
    })

    return {
        tab: currentTab.tab,
        size,
        tabs,
        isAnimationsEnabled,
        height,
        contentState,
        contentRef: ref,
        handlers,
        onSwitchTab: handleKeySwitchTab,
        onAdjustContent: handleContentAdjust
    }
}

// export interface PanelC

const context = createContext<PanelHandlers>({
    tab: null,
    onSwitchTab: () => () => Promise.resolve(undefined),
    onAdjustContent: noop
})

export function usePanelHandlers(): PanelHandlers {
    return useContext(context)
}

interface Props {
    panel: PanelControl
    innerClassName?: string
    isLighter?: boolean
    hideTabs?: boolean
    children?: ReactNode
}

const borderRadius = '24px'


export default function Panel({
    panel,
    innerClassName,
    isLighter,
    hideTabs,
    children
}: Props) {
    const control = panel as PanelControlInternal

    return (
        <context.Provider value={control.handlers}>
            <div
                className={cn("min-w-min relative dark:bg-neutral-900 bg-neutral-50", {
                    'w-full sm:w-9/12 md:w-7/12 lg:w-5/12 xl:w-4/12 2xl:w-3/12': control.size === 'default',
                    'transition-all duration-50': control.isAnimationsEnabled,
                    'w-full sm:w-11/12 md:w-8/12 lg:w-6/12 xl:w-5/12 2xl:w-4/12': control.size === 'wide',
                })}
                style={{ borderRadius }}
            >
                <div className=" absolute inset-0  z-10" />
                <div className={cn("z-20 flex flex-col items-stretch justify-start relative h-full transition-colors duration-800 ", {
                    'dark:bg-neutral-800 dark:bg-opacity-30 bg-opacity-20 bg-neutral-200': control.contentState !== 'visible',
                    'dark:bg-neutral-800 dark:bg-opacity-20 bg-neutral-100 bg-opacity-30': !isLighter,
                    'dark:bg-neutral-800 dark:bg-opacity-30 bg-neutral-60 bg-opacity-50': isLighter
                })}
                style={{ borderRadius }}
                >
                    <div
                        className={cn('prose prose-neutral dark:prose-invert max-w-none overflow-y-hidden transition-all duration-200 w-full')}
                        style={{ maxHeight: hideTabs ? 0 : 100 }}
                    >
                        <div className="p-6 flex flex-row items-center justify-center">
                            {control.tabs.map((tab, i) => (
                                <div
                                    key={tab.tab}
                                    onClick={control.onSwitchTab(tab.tab)}
                                    className={cn('select-none flex flex-row items-center justify-start hover:opacity-100 cursor-pointer mr-4 last:mr-0 text-lg transition-all duration-200 hover:text-black dark:hover:text-white', {
                                        'dark:text-rose-200 text-rose-300 opacity-100': tab.tab === control.tab,
                                        'opacity-40': tab.tab !== control.tab
                                    })}
                                >
                                    <div className='mr-3 whitespace-nowrap'>
                                        {tab.label}
                                    </div>
                                    {(i < control.tabs.length - 1) && tab.label && (
                                        <Arrow className='dark:fill-white fill-black opacity-40 w-4 -rotate-90 inline-block' />
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div
                        style={{ height: control.height, borderRadius }}
                        className={cn('prose max-w-none prose-neutral dark:prose-invert relative grow w-full', {
                            'transition-all duration-50': control.isAnimationsEnabled,
                        }, innerClassName)}
                    >
                        <div ref={control.contentRef} className={cn('w-full transition-opacity duration-120', {
                            'absolute': control.contentState === 'measure',
                            'opacity-100': control.contentState === 'visible',
                            'opacity-0': control.contentState !== 'visible',
                        })}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </context.Provider>
    )
}

export function PanelPadding({ children, className }: { children?: ReactNode, className?: string }) {
    return (
        <div className={cn("p-4 px-3", { }, className)}>
            {children}
        </div>
    )
}