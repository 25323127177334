import cn from 'classnames'
import { ChangeEventHandler, HTMLProps, KeyboardEventHandler, ReactNode, useMemo, useState } from 'react'
import { useEvent } from '../common/hooks'
import Arrow from './arrow.svg'
import { Tooltip } from './Tooltip'

type Props = Omit<HTMLProps<HTMLInputElement>, 'onChange'> & {
    value?: string
    error?: ReactNode | { current: ReactNode }
    onChange?: (value: string) => unknown
    isDisabled?: boolean
    min?: number
    max?: number
    style?: HTMLProps<any>['style']
    className?: string
}

let tooltipKeyId = 0

export default function NumberInput({ className, error, value, isDisabled, onChange, min, max, style, ...rest }: Props) {
    const [strValue, setStrValue] = useState<string | null>(null)

    const updateValue = useEvent((val: number) => {
        if (min) val = Math.max(min, val)
        if (max) val = Math.min(max, val)
        setStrValue(null)
        if (onChange) onChange(`${val}`)
    })

    const handleChange = useEvent((e => {
        if (onChange) onChange(e.target.value)
    }) as ChangeEventHandler<HTMLInputElement>)

    const handleNudgeValue = useEvent((d: number) => {
        if ((d > 0 && (max == null || +(value ?? 0) < max)) || (d < 0 && (min == null || +(value ?? 0) > min))) {
            updateValue(+(value ?? 0) + d)
        }
    })

    const handleKeyPress = useEvent(((e) => {
        if (e.key === 'ArrowUp') {
            handleNudgeValue(1)
        } else if (e.key === 'ArrowDown') {
            handleNudgeValue(-1)
        }
    }) as KeyboardEventHandler<HTMLInputElement>)

    const handleIncrement = useEvent(() => {
        handleNudgeValue(1)
    })

    const handleDecrement = useEvent(() => {
        handleNudgeValue(-1)
    })

    const tooltipKey = useMemo(() => ++tooltipKeyId, [error])

    return (
        <div className={cn("inline-block relative", className)} style={style}>
            <Tooltip
                key={tooltipKey}
                showOnCreate
                isHidden={!error} placement="bottom-start" size='small' trigger='manual'
                type="danger" content={(error && typeof error === 'object' && 'current' in error ? error.current : error)}
            >
                <div className='absolute inset-0 z-0'></div>
            </Tooltip>
            <input
                {...rest}
                disabled={isDisabled || rest.disabled}
                onKeyDown={handleKeyPress}
                onChange={handleChange}
                value={strValue ?? value}
                className={cn('inline-block relative z-10 w-full max-w-full bg-neutral-200 bg-opacity-50 dark:bg-opacity-100 dark:bg-neutral-800 outline-none border-0 rounded-2xl px-3 py-3 pr-8', {
                    'bg-red-200 dark:bg-red-900 dark:bg-opacity-25 bg-opacity-25 dark:placeholder:text-red-200 dark:placeholder:opacity-50 placeholder:text-red-800 placeholder:opacity-50': !!error,
                    'opacity-60': rest.disabled || isDisabled,
                })} />
            <Arrow onClick={handleIncrement} className={cn('select-none z-20 w-4 h-4 absolute rotate-180 fill-black dark:fill-white', {
                'opacity-10 cursor-default': (+(value ?? 0) === max) || (rest.disabled || isDisabled),
                'opacity-50 hover:opacity-100 cursor-pointer': (+(value ?? 0) !== max) && !(rest.disabled || isDisabled),
            })} style={{ top: '6px', right: '8px' }} />
            <Arrow onClick={handleDecrement} className={cn('select-none z-20 w-4 h-4 absolute fill-black dark:fill-white', {
                'opacity-10 cursor-default': (+(value ?? 0) === min) || (rest.disabled || isDisabled),
                'opacity-50 hover:opacity-100 cursor-pointer': (+(value ?? 0) !== min) && !(rest.disabled || isDisabled),
            })} style={{ bottom: '6px', right: '8px' }} />
        </div>
    )
}