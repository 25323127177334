import cn from 'classnames'
import { HTMLProps, memo, useEffect } from "react"
import create from 'zustand'
import Circle from '../ui/circle.svg'

const codeRemap: Record<string, string> = { 
    'usdc_goerli': 'usdc'
}

interface IconStore {
    data: { [code: string]: string | null }
    setIcon(code: string, data: string | null): void
    unsetIcon(code: string): void
}

const useIconStore = create<IconStore>(set => ({
    data: {},
    setIcon: (code, data) => set(prev => ({ ...prev, data: { ...prev.data, [code]: data } })),
    unsetIcon: (code) => set(prev => {
        const next = { ...prev, data: { ...prev.data } }
        delete next.data[code]
        return next
    }),
}))

function useIconData(code: string | null | undefined): string | null {
    const store = useIconStore()

    useEffect(() => {
        let aborted = false
        async function load(controller: AbortController) {
            try {
                const response = await fetch(getCryptoCodeUrl(code!), { signal: controller.signal })
                const data = await response.text()
                if (!data) return
                useIconStore.getState().setIcon(code!, `data:image/svg+xml;base64,${window.btoa(data)}`)
            } catch (ignore) {
                if (!aborted) useIconStore.getState().setIcon(code!, null)
            }
        }

        if (code && !(code in store.data)) {
            const controller = new AbortController()

            // TODO fixme race condition here, use SWR for sharing data
            // store.setIcon(code, null)
            load(controller)

            return () => {
                const store = useIconStore.getState()
                if (code && !store.data[code]) store.unsetIcon(code)
                aborted = true
                controller.abort()
            }
        }
    }, [code])

    if (!code) return null
    return typeof store.data[code] === 'string' ? store.data[code] : null
}

interface Props {
    code?: string | null
    size?: number
    className?: string
    style?: HTMLProps<any>['style']
}

function getCryptoCodeUrl(code: string): string {
    let prepared = code.toLowerCase()
    return `/crypto-icons/${prepared}.svg`
}

export default memo(function CryptoIcon({ code, className, style, size = 32 }: Props) {
    const data = useIconData(code ? codeRemap[code.toLowerCase()] ?? code : code)

    if (data) {
        return <img className={cn(className, 'relative')} src={data} width={size} height={size} alt="" style={{
            minWidth: `${size}px`,
            minHeight: `${size}px`,
            top: -1,
            ...(style ?? {}),
        }} />
    }

    if (!code) {
        return (
            <Circle style={{
                top: -1,
                ...(style ?? {})
            }} className={cn(className, "fill-neutral-200")} width={size} height={size} />
        )
    }

    return (
        <Circle
            className={cn(className, "dark:fill-neutral-700 fill-neutral-300 opacity-100 relative")}
            width={size}
            height={size}
            style={{
                top: -1,
                ...(style ?? {})
            }}
        />
    )
})