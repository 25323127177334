import { run } from "@repaya/commons/async2";
import { request } from "api/request";
import useSWR from "swr";
import { Coin } from "./types";

export function useSupportedCoins(onlyAvailable: boolean) {
    return useSWR(
        ['listSupportedCoins', onlyAvailable],
        (method, onlyAvailable) =>
            run(async function* () {
                const coins = yield* request<(Coin | { type: string })[]>(method, {
                    onlyAvailable
                })
                return (coins
                    .filter(coin => ['token', 'native', 'multi'].includes(coin.type)) as Coin[])
                    .reduce((acc, coin) => {
                        acc[coin.code] = coin
                        return acc
                    }, {} as Record<string, Coin>)
            }())
    )
}