export const noop = () => { }

export function is(x: any, y: any) {
    return (
        (x === y && (x !== 0 || 1 / x === 1 / y)) || (x !== x && y !== y)
    )
}

export function shallowEqual(objA: any, objB: any): boolean {
    if (is(objA, objB)) {
        return true
    }

    if (
        typeof objA !== 'object' ||
        objA === null ||
        typeof objB !== 'object' ||
        objB === null
    ) {
        return false
    }

    const keysA = Object.keys(objA)
    const keysB = Object.keys(objB)

    if (keysA.length !== keysB.length) {
        return false
    }

    for (let i = 0; i < keysA.length; i++) {
        const currentKey = keysA[i]
        if (
            !Object.hasOwnProperty.call(objB, currentKey) ||
            !is(objA[currentKey], objB[currentKey])
        ) {
            return false
        }
    }

    return true
}

export function isoPostWindowMessage(data: any, targetOrigin: string) {
    console.log('post', data, targetOrigin)
    if (typeof window === 'undefined') return
    if (!window.opener) return
    window.opener.postMessage(data, targetOrigin)
    window.opener.focus()
}